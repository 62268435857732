html, body{
    margin: 0;
    height: 100%;
    width: 100%;
    // background-color: #0d1114 !important;
    background: rgb(251,182,66);
    background: linear-gradient(180deg, rgba(251,182,66,1) 0%, rgba(115,0,0,1) 100%);
    
}

.bg-custom-dark{
    background-color: #0e1621 !important;
}

#root{
    width:100%;
    height:100%;
}
.container-md{
    background-color: #311b66;
}

header {
    height: 7vh;
    max-height:100px;
    background-color: #311b66;
}

.sign-out-area{
    text-align: right;
}

.btn-send{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    // height: 100%;
}

.form-control:focus {
    box-shadow: none !important;
}

.input-send{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    overflow-y: scroll;
    overflow-wrap: break-word;
    height: 100%;
    line-height: 1.5 !important;
    font-size: 1.2rem !important;
    padding:20px !important;
    background: rgb(58, 58, 58) !important;
    color: white !important;
    outline: none !important;
    border: none !important;
    padding-left:20px !important;
    padding-right:10px !important;
}

.recieve-message-form{
    height: 86vh;
    overflow-y: scroll;
    margin-bottom:0px;
}

.send-message-form{
    height: 7vh;
}

p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 5px 10px;
    // border-radius: 25px;
    position: relative;
    color: white;
    // text-align: center;
}
  
.message {
    display: flex;
    align-items: center;
}

.sent {
    flex-direction: row-reverse;
}

.sent p {
    color: white;
    background: #2b5278;
    align-self: flex-end;
}

.received p {
    background: #182533;
    color: white;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 1px) { 
    .custom-logo {
        height: 40px !important;
    }
 }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .custom-logo {
        height: 50px !important;
    }
 }

.received-name{
    color:#ffae00 !important;
    font-weight: bold;
}

.received-date, .received-time, .sent-time, .sent-date{
    font-size: 0.8rem;
}

.sent-name {
    display: none !important;
}

.profilePhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
}

.custom-empty{
    height:20px;
}

// Scrollbar Designs

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #2b5278 #0e1621;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: #0e1621;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #2b5278;
    border-radius: 20px;
    border: 3px solid #0e1621;
  }